import React from "react";
import { Link } from "gatsby";

type CookiePreferencesType = {
  copy: string;
  className?: string;
};

const CookiePreferences: React.FC<CookiePreferencesType> = ({
  copy,
  className,
}) => {
  const path = typeof window !== "undefined" ? window.location.pathname : "";

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (typeof window !== "undefined") {
      window.Osano.cm.showDrawer("osano-cm-dom-info-dialog-open");
      window.history.replaceState(null, "", path);
    }
  };

  return (
    <p className={className}>
      <Link
        data-testid="cookie-preference"
        aria-current={null}
        to={path}
        onClick={handleClick}
      >
        {copy}
      </Link>
    </p>
  );
};

export default CookiePreferences;
