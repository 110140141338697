import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import TextContentContainer from "../../containers/TextContentContainer/TextContentContainer";
import { useQueryParam, StringParam } from "use-query-params";
import { YoastSEOType } from "../../assets/Types";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { deIndexSeo } from "../../utils/deIndexSeo";

type TextTProps = {
  wpPage: {
    title: string;
    content: string;
    seo: YoastSEOType;
    template: {
      textPg: {
        textTitle: string;
      };
    };
  };
};

const TextPage: React.FC<PageProps<TextTProps>> = ({ data }) => {
  const [jobId] = useQueryParam("gh_jid", StringParam);
  const [userScale, setUserScale] = useState<boolean>(true);
  const { wpPage } = data;
  const {
    content,
    template: {
      textPg: { textTitle },
    },
  } = wpPage;

  useEffect(() => {
    if (window.location.pathname.includes("enterprise")) {
      setUserScale(false);
    }
  });

  return (
    <MainLayout hasFooterLargeCta={true}>
      {wpPage.seo && (
        <SEO post={deIndexSeo(wpPage as unknown as Queries.WpPage)} />
      )}
      <>
        {!userScale && (
          <Helmet
            meta={[
              {
                name: "viewport",
                content:
                  "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, user-scalable=0;",
              },
            ]}
          />
        )}
      </>
      <TextContentContainer
        jobId={jobId!}
        content={content}
        textTitle={textTitle}
      />
    </MainLayout>
  );
};

export default TextPage;

export const query = graphql`
  query TextTemplateQuery($id: String) {
    wpPage(id: { eq: $id }) {
      id
      content
      ...YoastSEOFragment
      template {
        templateName
        ... on WpTextTemplate {
          templateName
          textPg {
            textTitle
          }
        }
      }
    }
  }
`;
