import * as React from "react";
import { useEffect, useState } from "react";
import { TextContentType } from "../../assets/Types/AcfGroupTypes/TextContent.type";
import parse from "html-react-parser";
import greenhouseParser from "../../assets/util-scripts/greenhouse-parser";
import textContentScriptParser from "../../assets/util-scripts/text-content-script-parser";
import { StringParam, useQueryParam } from "use-query-params";
import { FormKeepScriptsType } from "../../assets/Types/FormKeepScripts.type";

import styles from "./TextContentContainer.module.scss";
import CookiePreferences from "../../components/CookiePreferences";

type ParsedHtml = string | JSX.Element | JSX.Element[];

const TextContentContainer: React.FC<TextContentType> = ({
  content,
  textTitle,
  withLargePb,
}) => {
  const [html, setHtml] = useState<ParsedHtml>();
  const [body, setBody] = useState<ParsedHtml>();
  const [jobId] = useQueryParam("gh_jid", StringParam);

  useEffect(() => {
    let wysiwyg, wysiwygBody;
    const scriptObj: FormKeepScriptsType = {
      pym: null,
      formKeep: null,
    };
    if (textTitle) {
      wysiwyg = parse(textTitle, {
        replace: node => greenhouseParser(node, jobId),
      });
    }
    if (content) {
      wysiwygBody = parse(content, {
        replace: node => {
          if (node.type && node.type === "tag") {
            if (node["children"][0]?.data === "cookie-preferences") {
              return <CookiePreferences copy="Cookie Preferences" />;
            }
          }

          return textContentScriptParser(node, scriptObj);
        },
      });
    }

    !html && setHtml(wysiwyg);
    !body && setBody(wysiwygBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [html, body]);

  const sectionStyles = `pad-top--large-sans-header ${
    withLargePb ? "pad-bottom--large" : null
  }`;

  return (
    <section className={sectionStyles}>
      <div className={`full-width narrow`}>
        <h1 className={styles.textContent}>{html}</h1>
        <div className={styles.bodyContent}>{body}</div>
      </div>
    </section>
  );
};

export default TextContentContainer;
