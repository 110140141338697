import { DOMNode } from "html-react-parser";
import { Element } from "domhandler/lib/node";

const greenhouseParser = (node: DOMNode, jobId?: string) => {
  if (node instanceof Element && node.type === "script") {
    if (node.attribs.src) {
      const script = document.createElement("script");
      script.src = node.attribs.src;
      script.onload = function () {
        if (jobId) {
          window.Grnhse?.Iframe.load(+jobId);
        } else {
          window.Grnhse?.Iframe.load();
        }
      };
      document.body.append(script);
    }
    return;
  }
  return node;
};

export default greenhouseParser;
