import { DOMNode } from "html-react-parser";
import { Element } from "domhandler/lib/node";
import { FormKeepScriptsType } from "../Types/FormKeepScripts.type";

/**
 * Passed to the react-parser libraries replace method.
 * Built out to handle Formkeep script in the TextContent container of the text template.
 * Can also handle single scripts in body content.
 * @param node - Domnodes that are cycled through in react-parser
 * @param scripts - Object that contains the formKeep and pym scripts, pym must load first.
 */
export default (node: DOMNode, scripts: FormKeepScriptsType) => {
  if (node instanceof Element && node.type === "script") {
    if (node.attribs.src) {
      const script = document.createElement("script");
      const src = node.attribs.src;
      if (src.includes("pym")) {
        script.src = src;
        scripts.pym = script;
      } else if (src.includes("formkeep")) {
        script.src = src;
        scripts.formKeep = script;
      } else {
        script.src = src;
        document.body.append(script);
      }
      if (scripts.pym && scripts.formKeep) {
        scripts.pym.onload = () => {
          document.body.append(scripts.formKeep);
        };
        document.body.append(scripts.pym);
      }
    }
    return;
  }
  return node;
};
